<template>
  <div id="staff">
    <v-container>
      <div class="operate d-flex justify-space-between">
        <v-btn
          dark
          color="primary"
          class="add align-self-center"
          :to="'staff/add'"
        >
          <v-icon dark> mdi-plus </v-icon>
        </v-btn>
        <div class="search d-flex">
          <div class="align-self-center">
            <v-text-field
              v-model="options.key"
              label="请填写账号或者姓名"
              required
              clearable
            ></v-text-field>
          </div>
          <div class="date align-self-center ml-4">
            <v-menu
              v-model="startTimeEnabled"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="options.startTime"
                  label="请填写开始时间"
                  prepend-icon="mdi-calendar"
                  readonly
                  clearable
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="options.startTime"
                @input="startTimeEnabled = false"
              ></v-date-picker>
            </v-menu>
          </div>
          <div class="date align-self-center ml-4">
            <v-menu
              v-model="endTimeEnabled"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="options.endTime"
                  label="请填写结束时间"
                  prepend-icon="mdi-calendar"
                  readonly
                  clearable
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="options.endTime"
                @input="endTimeEnabled = false"
              ></v-date-picker>
            </v-menu>
          </div>
          <div class="align-self-center ml-4">
            <v-btn @click="load">搜索</v-btn>
          </div>
        </div>
      </div>
      <div class="content">
        <v-data-table
          :headers="table.headers"
          :items="table.page.data"
          :loading="table.loading"
          hide-default-footer
        >
          <template v-slot:item.actions="{ item }">
            <v-btn color="error" x-small plain @click="deleteItem(item)">
              删除
            </v-btn>
            <v-btn color="primary" x-small plain @click="editItem(item)">
              编辑
            </v-btn>
          </template>
        </v-data-table>
        <div class="text-center pt-4">
          <v-pagination
            v-model="options.page"
            :length="table.page.pageCount"
          ></v-pagination>
        </div>
      </div>
    </v-container>
    <v-dialog v-model="table.deleteDialog" max-width="290">
      <v-card>
        <v-card-title class="headline"> 确认 </v-card-title>

        <v-card-text> 您确定要删除该项码？ </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn @click="table.deleteDialog = false"> 取消 </v-btn>

          <v-btn color="error" @click="deleteOk"> 确定 </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  methods: {
    load() {
      if (this.table.loading) return;
      this.table.loading = true;
      this.$shareyue.replaceRoute(this.options);
      this.$shareyueApi
        .getStaffs(this.options)
        .then((data) => {
          this.options.page = data.page + 1;
          this.table.page = data;
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
    editItem(item) {
      this.$router.push({
        path: "staff/add",
        query: {
          id: item.id,
        },
      });
    },
    deleteItem(item) {
      this.table.deleteDialog = true;
      this.table.deleteItem = item;
    },
    deleteOk() {
      var index = this.table.page.data.find((p) => p == this.table.deleteItem);
      if (index != -1) {
        this.table.page.data.splice(index, 1);
        this.$shareyueApi.deleteStaff(this.table.deleteItem.id);
      }
      this.table.deleteDialog = false;
    },
  },
  watch: {
    "options.page"() {
      this.load();
    },
  },
  created() {
    this.load();
  },
  data() {
    return {
      options: {
        key: "",
        startTime: "",
        endTime: "",
        page: 1,
        pageSize: 10,
      },
      startTimeEnabled: false,
      endTimeEnabled: false,
      table: {
        page: {},
        loading: false,
        headers: [
          {
            text: "账号",
            sortable: false,
            value: "account",
            width: 200,
          },
          {
            text: "姓名",
            sortable: false,
            value: "name",
          },
          {
            text: "登录时间",
            sortable: false,
            align: "center",
            value: "logined",
            width: 200,
          },
          {
            text: "创建日期",
            sortable: false,
            align: "center",
            value: "created",
            width: 170,
          },
          {
            text: "操作",
            sortable: false,
            align: "center",
            value: "actions",
            width: 130,
          },
        ],
        deteleItem: null,
        deleteDialog: false,
      },
    };
  },
};
</script>
<style lang="less" scoped>
</style>
